import React from "react";

import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout
    seo={{
      metaTitle: "404: Not found",
      metaDescription: "It looks like you got lost",
    }}
  >
    <div className="uk-section">
      <div className="uk-container">
        <h1>NOT FOUND</h1>
        <p>You just hit a page that doesn't exist... the sadness.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
